<template>
  <div class="wrapper">
    <div class="xa-cell">
      <div class="xa-flex">
        <img
          class="logo-img"
          src="https://xag-static-cdn.oss-cn-hangzhou.aliyuncs.com/logo/xag/logo_cn.png"
          title="XAG"
        />
      </div>
      <div class="head-remark xa-txt-11">
        <p class="xa-txt-12" v-for="remark in remarks" :key="remark">
          {{ remark }}
        </p>
        <p class="xa-txt-12">{{ sign }}</p>
      </div>
    </div>
    <div class="head-title">
      {{ title }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    remarks: {
      type: Array,
      default() {
        return ['设备：214C00086', '时间：2017-10-28']
      }
    },
    sign: {
      default: '@极飞技术支持部'
    },
    title: {
      default: '数据分析结果报告'
    }
  }
}
</script>
<style scoped>
.xa-cell {
  align-items: center;
}
.logo-img {
  height: 38px;
  max-width: 40vw;
  object-fit: contain;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
  height: 38px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #3333339c;

}
.head-remark {
  color: #909090;
  line-height: 16px;
}
</style>
