<template>
    <section class="wrapper">
        <pageheader v-if="head.title" :title="head.title" :sign="head.sign" :remarks="head.remarks"></pageheader>
        <template v-for="(content,index) in contents">
            <pagecontent @preview="onPreview" :key="index" :title="content.title" :contents="content.contents" :img="content.img"></pagecontent>
        </template>
    </section>
</template>
<script>
import pageheader from '@/components/ensure/report/report-header.vue'
import pagecontent from '@/components/ensure/report/report-content.vue'
import {ImagePreview} from 'vant'
import { setDocTitle } from '@/utils/pages'
import diyDetail from '@/mixins/diyDetail'
export default {
    components: { pageheader, pagecontent },
    mixins:[diyDetail],
    data() {
        return {
            head: {
                title: '',
                sign: '',
                remarks: []
            },
            contents: []
        }
    },
    methods: {
        onPreview(img) {
            // eslint-disable-next-line no-console
            console.log(img)
            // this.$wx().then(wxController => wxController.previewImage(img, [img]))
            const instance = ImagePreview({
                images: [img],
                startPosition: 0
            })
            this.preViewInstance = Object.freeze({
                close: instance.close
            })
        }
    },
    beforeDestroy() {
        if (this.preViewInstance) {
        this.preViewInstance.close()
        this.preViewInstance = null
        }
    },
    async mounted() {
        const { url, data } = this.$_extractRequestInfoFormRoute()
        const result =  await this.$_request({ url, data })
        Object.assign(this, result)
        setDocTitle(result.doc_title)
    }
}
</script>
<style scoped>
.wrapper {
  max-width: 720px;
  margin: 0 auto;
  padding: 8px 8px;
  overflow: auto;
  box-sizing: border-box;
  background: #fff;
}
</style>
<style>
@media screen and (min-width: 762px) {
    .max-page-width,#app{
        height: max-content !important;
        max-width: initial;
    }
    .max-page-width section.wrapper {
        max-width: 762px !important;
        width: 762px !important;
        overflow: initial !important;
        margin: 0 auto;
        padding: 20px 20px;
    }
    .max-page-width div.wrapper {
        max-width: initial !important;
    }
    .content__title{
        font-size: 16px !important;
        text-indent:0 !important;
    }
    .content__title::before{
        position:  relative !important;
        display: inline-block;
        padding-right: 4px;
        content: '●' !important;
        top: 50% !important;
        left: 0% !important;
        background: transparent !important;
        width: initial !important;
        height: initial !important;
        color: #000;
        top: 2px !important;
        font-size: 30px;
        line-height: 1;
    }
    .content__item{
        font-size: 14px !important;
        min-width: 33.333% !important;
    }
    .content__item:nth-of-type(2n){
        padding-left: 0px !important;
    }
    .content__img {
        width: initial !important;
        max-width: 100% !important;
    }
}
@page{
    margin: 1cm 1cm 4.5mm;
}
@media print {
    .max-page-width,#content-box,#app,body {
        max-width: 100% !important;
        width: 100% !important;
        padding:0 0px;
        margin:0 0px;
    }
    .max-page-width section.wrapper {
        max-width: 100% !important;
        overflow: initial !important;
        width: 100% !important;
        padding:0 0px;
        margin:0 0px;
        border: none;
        background: pink;
    }
    .content__title {
        text-indent: 0px !important;
        font-size: 16px !important;
        page-break-after: avoid;
    }
    .content__title::before{
        position: relative !important;
        display: inline-block;
        padding-right: 4px;
        content: '●' !important;
        top: 50% !important;
        left: 0% !important;
        background: transparent !important;
        width: initial !important;
        height: initial !important;
        color: #000;
        top: 2px !important;
        font-size: 30px;
        line-height: 1;
    }
    .content__item{
        min-width: 33.333% !important;
    }
    .content__wrap {
        page-break-before:avoid;
    }
}
</style>
