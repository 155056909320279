<template>
    <div class="content__wrap">
        <p class="content__title">{{title}}</p>
        <div class="clearfix">
            <li class="fl content__item xa-txt-12" v-for="(content,index) in contents" :key="index">
                <span class="content__label">{{content.key?content.key+' ：':''}}</span>
                <span class="content__value">{{content.value}}</span>
            </li>
        </div>
        <img v-show="img.thumb_url" class="content__img" :src="img.thumb_url" @click="$emit('preview',img.url)">
    </div>
</template>
<script>
export default {
    props: {
        title: {
            default: '基本信息'
        },
        contents: {
            type: Array,
            default() {
                return [
                    {
                        key: '飞手',
                        value: '张飞'
                    },
                    {
                        key: '保障',
                        value: '李四'
                    },
                    {
                        key: '时间',
                        value: '2017-10-26'
                    },
                    {
                        key: '分析工程师',
                        value: '郑晓欢'
                    },
                    {
                        key: '地点',
                        value: '广东省广州市天河区思成路'
                    },
                    {
                        key: '事故现象',
                        value: '炸鸡、GPS迫降'
                    },
                    {
                        key: '事故原因',
                        value: '航线内撞击障碍物、GPS突变'
                    },
                    {
                        key: '',
                        value: '飞机一起飞卫星就掉到0了，先检查下GPS主天线有无松动，建议更换试试。'
                    }
                ]
            }
        },
        img: {
            default() {
                return {}
            }
        }
    }
}
</script>
<style scoped>
.content__wrap {
  margin: 0 16px 16px;
  color: #909090;
}
.content__title {
  color: #3333339c;
  position: relative;
  font-weight: bold;
  text-indent: 20px;
  line-height: 25px;
}
.content__title::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 10px;
  width: 5px;
  height: 5px;
  background: #555555;
  border-radius: 50%;
}
.clearfix{
  list-style:none;
}
.content__item {
  box-sizing: border-box;
  min-width: 50%;
  line-height: 24px;
}
.content__item:nth-of-type(2n){
    padding-left: 20px;
}
.content__img {
  display: block;
  width: 100%;
}
</style>
